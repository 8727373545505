import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Amplify, Auth } from 'aws-amplify';
import { ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import MenuAppBar from './Shared/MenuAppBar';
import Authentication from './Register/Authentication';
import { RegisterActions, signedIn, signUp } from './Actions/RegisterActions';
import PrivateRoute from './Shared/PrivateRoute';
import CircularIndeterminate from './Shared/CircularIndeterminate';
import Notifier from './Shared/Notifier';
import ErrorBoundary from './Shared/ErrorBoundary';
import { AUTH_ACCESS_METHOD, QueryParameterNames,
    DailyWorkoutPages, AllowedEntryPaths, EntryPaths } from './Shared/Constants';
import ComponentWrapper from './Shared/ComponentWrapper';
import { materialTheme } from './Styles/materialTheme';
import SplashScreen from './components/SplashScreen/SplashScreen';
import LoadingScreen from './components/SplashScreen/LoadingScreen';
import { SessionStorageVariables } from './Shared/Constants';
import { Tab, Tabs, Typography } from '@material-ui/core';
import TabPanel from './Register/TabPanel';
import PublicRoute from './Shared/PublicRoute';
import { AppStateProvider } from './Shared/UpAppStateProvider';
import { appointmentCaptured, organisationCaptured } from './Actions/CustomerDetailsActions';
import RiskAssessment from './CustomerDetails/RiskAssessment';
import { getOrganisationDetails } from './HttpHelpers/CustomerServiceHttpHelper';
import { getApplicablePages } from './Shared/Services/PageService';

const awsConfig = require('./aws-exports.json');
const awsCognitoConfig = require('./aws-cognito-config.json');
const appSyncConfig = require('./aws-appsync-config.json');

const amplifyConfig = Object.assign({}, awsConfig, awsCognitoConfig);
amplifyConfig.storage = sessionStorage;
Amplify.configure(amplifyConfig);
Amplify.configure(appSyncConfig);
Auth.configure({ ...awsConfig, storage: sessionStorage });

//require('dotenv').config();
const AuthTab = ({ tabValue, dispatch, router, register, nextPage }) => {
    return (
        <>
            <TabPanel value={tabValue} index={0}>
                <Authentication
                    authState={RegisterActions.SIGN_IN}
                    {...register}
                    dispatch={dispatch}
                    router={router}
                    nextPage={nextPage}
                />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Authentication
                    authState={RegisterActions.SIGN_UP}
                    {...register}
                    dispatch={dispatch}
                    router={router}
                    nextPage={nextPage}
                />
            </TabPanel>
        </>
    );
};

class App extends Component {
    state = {
        isLoaded: false,
        isDev: false,
        authMethod: AUTH_ACCESS_METHOD.DEFAULT,
        tabValue: 0,
        applicablePages: undefined,
        applicablePagesSteps: 4,
        hideStepper: null,
        programName: null,
        flow: null,
        orgKey: null,
        isPrivateRoute: null
    };
 
    componentDidMount() {
        if(window.location.pathname !== '/login') {
            this.setState({ tabValue: 1 });
        }
        
        // temp code
        const url = window.location.href;
        if (url && url.toLocaleLowerCase() === 'https://subscribe-dev.universalpractice.com.au/brief-assessment?p=vpa&o=AAIXs7RiCq&f=bnw'.toLocaleLowerCase())
        {
            window.location.href = 'https://subscribe.universalpractice.com.au/brief-assessment?p=vpa&o=AAIXs7RiCq&f=bnw';
            return;
        }
             

        this.setupFlowAndPages();

        Auth.currentAuthenticatedUser()
            .then((user) => {
                // this is the case where user click refresh (f5) on browser
                const patientString = sessionStorage.getItem(SessionStorageVariables.Patient);
                if(patientString) {
                    const patient = JSON.parse(patientString);
                    user = { ...user, ...patient };
                }
                this.props.dispatch(signedIn(user));

                const appointmentString = sessionStorage.getItem(SessionStorageVariables.PatientAppointmentData);
                if (appointmentString) {
                    const appointment = JSON.parse(appointmentString);
                    appointment.patientId = user.patientId;
                    appointment.subscriptionId = this.props.customerDetails.subscription.id;
                    this.props.dispatch(appointmentCaptured(appointment));
                }
            })
            .catch(() => {
                this.props.dispatch(signUp());
            })
            .finally(() => {
                this.setState({ isLoaded: true });
            });
    }

    handleChange = (e, value) => {
        this.setState({ tabValue: value });
    }

    loginComponent = () => {
        const searchQuery = this.props.router.location.search;
        const autoLogin = new URLSearchParams(searchQuery).get(QueryParameterNames.T);
        return (!autoLogin ?        
            <>
                <MenuAppBar />
                <Typography variant="h1"> Get Started</Typography>
                <div style={{
                    color: '#ffffff', display: 'flex', justifyContent: 'center', marginTop: '43px'
                }}>
                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                    >
                        <Tab label="Login" value={0} className="up-tab" />
                        <Tab label="Register" value={1} className="up-tab" />
                    </Tabs>
                </div>
                <AuthTab tabValue={this.state.tabValue} dispatch={this.props.dispatch} register={this.props.register} router={this.props.router} />
            </> :
        //risk assessment, follow up, ncb, recommendation
            
            <AuthTab tabValue={this.state.tabValue} dispatch={this.props.dispatch} register={this.props.register} router={this.props.router} nextPage={this.state.applicablePages?.length > 0 ? this.state.applicablePages[0].path : null}/>);

    }


    async setupFlowAndPages() {
        const searchQuery = this.props.router.location.search;
        const urlParams = new URLSearchParams(searchQuery);
        const programName = urlParams.get(QueryParameterNames.P) ?? '';
        let flow = urlParams.get(QueryParameterNames.F) ?? '';
        let orgKey = urlParams.get(QueryParameterNames.O) ?? '';
        const hideStepper = urlParams.get('hideStepper');
        const entryPath = window.location.pathname.substring(1);
        const permittedEntryPath = entryPath ? AllowedEntryPaths.includes(entryPath) : false;

        if (entryPath === EntryPaths.InStudio && !flow)
        {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = `/${EntryPaths.InStudio}?f=isa`;          
            return;
        }

        if (entryPath && !permittedEntryPath)
        {            
            flow = AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION;
            orgKey = entryPath;            
            localStorage.clear();
            sessionStorage.clear();
        }
       
        this.setState({ programName, flow, hideStepper, orgKey });
        
        if(orgKey) {          
            let organisation = sessionStorage.getItem(SessionStorageVariables.Organisation);
            if (!organisation)
            {
                var orgResponse = await getOrganisationDetails(orgKey);
                {
                    if (orgResponse.ok)
                    {
                        var orgResult = await orgResponse.json();
                        organisation = orgResult.organisations[0];
                        this.props.dispatch(organisationCaptured(organisation));
                        if(flow === AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION) {     
                            //redirect from short url to brief assessment    
                            window.location.href = `/brief-assessment?f=org&o=${orgKey}`;
                        }
                    }
                    else
                        window.location.href = '/';
                }
            }
            else
                this.props.dispatch(organisationCaptured(JSON.parse(organisation)));//  react redux lost its value after redirect, so ensure we set it again                 
        }

        const applicablePages = getApplicablePages(flow, programName);
        this.setState({ applicablePages: applicablePages.pages });
        this.setState({ applicablePagesSteps: applicablePages.steps });
        this.setState({ isPrivateRoute : applicablePages.isPrivateRoute });

       
    }

    render() {
        
        return (
            <div>
                {this.state.isLoaded ? (
                    <div className="App">
                        <ThemeProvider theme={materialTheme}>
                            <AppStateProvider>
                                <ErrorBoundary>
                                    {
                                        // !this.props.register.auth.isAuthenticated && (<MenuAppBar
                                        //     {...this.props.register}
                                        //     dispatch={this.props.dispatch}
                                        // />)
                                    }
                                    < Switch >
                                        <>
                                            <>{this.state.programName && this.state.applicablePages && this.state.applicablePages.map((page) => (
                                                <PublicRoute key={page.key} path={page.path}>
                                                    <ComponentWrapper
                                                        component={page.component}
                                                        {...this.props.register}
                                                        {...this.props.customerDetails}
                                                        title={page.title}
                                                        nextPage={page.nextPage}
                                                        alternateExit={page?.alternateExit}
                                                        previousPage={page.previousPage}
                                                        dispatch={this.props.dispatch}
                                                        stepNum={page.stepNum}
                                                        numberOfSteps={this.state.applicablePagesSteps}
                                                        hideStepper={this.state.hideStepper ? this.state.hideStepper === 'true' : false}
                                                    />
                                                </PublicRoute>
                                            ))}
                                            </>
                                            <>{!this.state.programName && !this.state.isPrivateRoute && this.state.applicablePages && this.state.applicablePages.map((page) => (
                                                <PublicRoute key={page.key} path={page.path}>
                                                    <ComponentWrapper
                                                        component={page.component}
                                                        {...this.props.register}
                                                        {...this.props.customerDetails}
                                                        title={page.title}
                                                        nextPage={page.nextPage}
                                                        alternateExit={page?.alternateExit}
                                                        previousPage={page.previousPage}
                                                        dispatch={this.props.dispatch}
                                                        stepNum={page.stepNum}
                                                        numberOfSteps={this.state.applicablePagesSteps}
                                                        hideStepper={this.state.hideStepper ? this.state.hideStepper === 'true' : false}
                                                    />
                                                </PublicRoute>
                                            ))}
                                            </>
                                            <>{!this.state.programName && this.state.isPrivateRoute && this.state.applicablePages && this.state.applicablePages.map((page) => (
                                                <PrivateRoute key={page.key} path={page.path}>
                                                    <ComponentWrapper
                                                        component={page.component}
                                                        {...this.props.register}
                                                        {...this.props.customerDetails}
                                                        title={page.title}
                                                        nextPage={page.nextPage}
                                                        alternateExit={page?.alternateExit}
                                                        previousPage={page.previousPage}
                                                        dispatch={this.props.dispatch}
                                                        stepNum={page.stepNum}
                                                        numberOfSteps={this.state.programName ? 4 : 3}
                                                    />
                                                </PrivateRoute>
                                            ))}
                                            </>
                                            <>   
                                                <Route exact path="/">
                                                    <this.loginComponent />
                                                </Route>
                                                <Route exact path="/login">
                                                    <this.loginComponent />
                                                </Route>
                                                <Route exact path="/risk-assessment">                                                    
                                                    <MenuAppBar
                                                        {...this.props}
                                                        dispatch={this.props.dispatch}
                                                        title={'Risk Assessment'}
                                                    >
                                                    </MenuAppBar>
                                                    <RiskAssessment />
                                                </Route>
                                            </>
                                        </>                                        
                                    </Switch>
                                </ErrorBoundary>
                            </AppStateProvider>
                        </ThemeProvider>
                    </div>
                ) : (
                    <CircularIndeterminate />
                )
                }
                <Notifier />
            </div >
        );
    }
}

export default connect(mapStateToProps)(
    App ? SplashScreen(App) : LoadingScreen
);

function mapStateToProps(store) {
    return {
        register: store.register,
        customerDetails: store.customerDetails,
        router: store.router,
        stepNum: store.customerDetails.stepNum,
    };
}
